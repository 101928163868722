import React from 'react';
import Helmet from 'react-helmet'
import '../../Content/css/tips.css';
import Sidebar from '../Products/ProductSidebar';
import PageHeader from '../PageComponents/PageHeader'
import Html from 'react-html-parser';
let siteURL = process.env.REACT_APP_APP_URL;
let gravURL = process.env.REACT_APP_GRAV_URL;
export default function Tips(props) {
	let tips = props.pageContext.frontmatter.tips.map((item, key) =>
		<div key={key} className="indentedList">
			<h5 className="orangeText">{item.heading}</h5>
			<div>
				<span className="flaticon-right-arrow"></span>
				{Html(item.tip)}
			</div>
		</div>
	);
	let {title,keyWords,url,facebookTitle,facebookDesc,shareImg,googleDesc,} = props.pageContext.head;
	return (
		<main>
			<Helmet>
				<title>Products | New Image Keto | Alpha Lipid™ SDII Programme</title>	
				{googleDesc&&<meta name="description" content={googleDesc}/>}
				{keyWords&& <meta name="keywords" content={keyWords.toString()} />}
				<meta property="og:image:width" content="1200" />
				<meta property="og:image:height" content="630" />
				<meta property="og:url" content={url?siteURL + url:siteURL} />
				<meta property="og:title" content={facebookTitle?facebookTitle:title}/>
				<meta name="twitter:title" content={facebookTitle?facebookTitle:title}/>
				<meta property="og:description" content={facebookDesc?facebookDesc:googleDesc} />
				<meta name="twitter:description" content={facebookDesc?facebookDesc:googleDesc}/>
				<meta property="og:image" content={shareImg} />
				<meta property="og:image:url" content={shareImg}/>
				<meta name="twitter:image" content={shareImg}/>
				<meta name="twitter:card" content={shareImg}/>
			</Helmet>
			<div className="pageGrid clearfix">
				<section>
					<PageHeader mainHeading="5 Tips" subHeading="To Help Make Keto<br/>Part Of Your Everyday Life"/>
				</section>
				<section>
					<div className="pageGrid clearfix">
						<div className="md:flex flex-wrap">
							<div className="lg:w-2/12"></div>
								<div className="md:w-7/12 lg:w-5/12 otherTipsTextWrapper">
									{tips}
								</div>
							<div className="lg:2/12 md:w-1/12"></div>
							<div className="lg:w-3/12 md:w-4/12">
								<Sidebar/>
							</div>
						</div>
					</div>
				</section>
			<section>
				<PageHeader mainHeading="Keto Pyramid" subHeading="Real Food &ensp;|&ensp; Real Science &ensp;|&ensp; Real Results"/>
			</section>
			<img src={`${gravURL}/uploads/images/${props.pageContext.frontmatter.ketoPyramid}`} alt="keto pyramid" className="ketoPyramidImg"/>
			</div>
		</main>
	);
}
