import React from 'react';
import HtmlParser from 'react-html-parser';
const PageHeader = (props) => {
	return (
		<div className={"pageHeader " + props.size}>
			<h2 className="mainHeading">{HtmlParser(props.mainHeading)}</h2>
			<h3 className="subHeading">{HtmlParser(props.subHeading)}</h3>
		</div>
	);
}
export default PageHeader;