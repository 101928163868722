import React from 'react';
import BlogImage from '../../Content/images/ProductSidebar/blog.jpg'
import RecipesImage from '../../Content/images/ProductSidebar/recipes.jpg'
import {Link} from 'gatsby'
let ReactFitText = require('react-fittext');
const ProductSidebar = () => {
	return (
		<div className="productSidebar">
			<div className="sidebarRecipesTile sidebarTile">
				<img src={RecipesImage} alt="Recipes Page"/>
				<div className="buttonWrapper">
					<Link to="/recipes" className="bg-primary text-white rounded-lg inline-block py-2 px-4">
						Recipes
					</Link>
				</div>
			</div>
			<div className="sidebarTile">
				<div className="textTile">
					<Link to="/keto-calculator">
						<ReactFitText compressor={0.8}><div className="whiteText weight700">work out </div></ReactFitText>
						<ReactFitText compressor={0.85}><div className="whiteText weight700">your daily </div></ReactFitText>
						<ReactFitText compressor={0.6}><div className="orangeText weight700">macros</div></ReactFitText> 
						<ReactFitText compressor={0.75}><div className="whiteText weight700">with our </div></ReactFitText>
						<ReactFitText compressor={0.37}><div className="orangeText weight700">keto </div></ReactFitText>
						<ReactFitText compressor={0.85}><div className="orangeText weight700">calulator</div></ReactFitText>
					</Link>
				</div>
			</div>
			<div className="imgTextTile">
				<Link to="/frequently-asked-questions">
				<img src={BlogImage} alt="Blog Page"/>
				<div className="textWrapper text-center">
					<button>
						we tackle those
					</button>
					<ReactFitText compressor={0.48}>
						<div className="weight700 orangeText">faqs</div>
					</ReactFitText>
				</div>	
				</Link>
			</div>
		</div>
	);
}
export default ProductSidebar;